import Error404 from './pages/Error404.vue';
import Home from './pages/Home.vue';
import SignUp from './pages/SignUp.vue';
import EarlyAccess from './pages/earlyAccess.vue';
import Router from 'vue-router'
import Vue from 'vue'

export const routerData = new Vue({
  data: {
    errorData: {}
  }
})

function resetRouterData() {
  Vue.set(routerData.errorData, 'message', '')
  Vue.set(routerData.errorData, 'redirectName', '')
  Vue.set(routerData.errorData, 'redirectQuery', {});
  const win = (window as any)
  win.errorData = null
}

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: EarlyAccess
    },
    {
      path: "*",
      name: "NotFound",
      component: Error404
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

declare global {
  interface Window {
    router: Router
  }
}

window.router = router

export default router
