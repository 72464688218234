import axios from "axios";

const httpCoreEndpoint = process.env.VUE_APP_CAMP360CORE_API_URL || "http://localhost:3019";
const env = process.env.VUE_APP_ENV || "dev";

export const callApi = async (
  method: "post" | "get" | "put" | "delete" | "patch",
  endpoint: string,
  data: any = {},
  params?: any,
) => {
  let apiRoute = "";
  if (env == "prod" )
  {
    apiRoute = process.env.PROD_API_URL;
  }
  else
  {
    apiRoute = httpCoreEndpoint + endpoint;
  }
  const result = await axios({
    method: method,
    url: apiRoute,
    data: JSON.stringify(data),
    params: params,
    headers: {
      "Content-Type": "application/json",
    }
  })
    .then((response: any) => {
      return response.data;
    })
    .catch(error => {
      const errorResponse = error.response || {};
      const errorData = errorResponse.data || {};
      const errorMessage =
        errorData.message || error.message || JSON.stringify(error);
      return {
        status: errorResponse.status || 0,
        message:
          errorMessage ||
          errorResponse.statusText ||
          "Something was wrong. Please try it later.",
        data: error
      };
    });

  return result;
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
