
  import { Component as TSXComponent } from "vue-tsx-support";
  import { Component } from "vue-property-decorator";
  import FormText from '@/components/Form/EAFormText.vue';
  import FormEmail from "@/components/Form/EAFormEmail.vue";
  import FormPassword from "@/components/Form/EAFormPassword.vue";
  import { ApiHelper } from "@/helpers";
  import Loading from "@/components/Common/Loading.vue";
  import $ from "jquery";

  
  
  @Component({
    inheritAttrs: false,
    components: {
      Loading,
      FormPassword,
      FormEmail,
      FormText,
    },
  })
  export default class SignUp extends TSXComponent<void> {  
    formData: any = {
        isLoading: false,
        controls: {
            email: {
                label: "EMAIL ADDRESS",
                placeholder: "YOUR EMAIL ADDRESS",
                value: "",
                error: ""
            },
            firstName: {
                label: "FIRST NAME",
                placeholder: "FIRST NAME",
                value: "",
                error: ""
            },
            lastName: {
                label: "LAST NAME",
                placeholder: "LAST NAME",
                value: "",
                error: ""
            },
            name: {
                label: "ORGANIZATION NAME",
                placeholder: "ORGANIZATION NAME",
                value: "",
                error: ""
            },
            campers: {
                label: "CAMPER POPULATION",
                placeholder: "CAMPER POPULATION",
                value: "",
                error: "",
                disabled: true
            },
        }
    };
    mounted() {
        window.addEventListener("load", function () {
            document.documentElement.classList.remove("global-preload");
        });

        // Sidebar
        const overlay = document.querySelector(".overlay");
        const hamburger = document.querySelector(".btn-hamburger");
        const sidebar = document.querySelector(".sidebar");

        if (hamburger) {
            const toggleSidebar = function () {
                hamburger.classList.toggle("btn-hamburger_active");
                sidebar.classList.toggle("sidebar_active");
                overlay.classList.toggle("overlay_active");
            };
            hamburger.addEventListener("click", toggleSidebar);
            document.addEventListener("keydown", function (e) {
                if (overlay.classList.contains("overlay_active") && e.key === "Escape") {
                toggleSidebar();
                }
            });
            overlay.addEventListener("click", function () {
                toggleSidebar();
            });
        }

        // Custom Select

        document.querySelectorAll(".select").forEach(function (selectWrapper) {
        const selectBtn = selectWrapper.querySelector(".select__button");
        const selectList = selectWrapper.querySelector(".select__list");
        const selectListItems = selectList.querySelectorAll(".select__item");
        const selectInput = selectWrapper.querySelector(".input-field.position-absolute");

        const hideOptions = function () {
            selectBtn.classList.remove("select__button_active");
            selectList.classList.remove("select__list_visible");
        };

        selectBtn.addEventListener("click", function (e) {
            e.preventDefault();
            selectList.classList.toggle("select__list_visible");
            this.classList.add("select__button_active");
        });

        selectBtn.addEventListener("focus", function (e) {
            e.preventDefault();
            selectList.classList.toggle("select__list_visible");
            this.classList.add("select__button_active");
            $(".select__list_visible .select__item:first-child").addClass("active");
        });

        selectListItems.forEach(function (listItem) {
            listItem.addEventListener("click", function (e) {
            e.stopPropagation();
            selectBtn.innerText = this.innerText;
            selectBtn.focus();
            selectInput.value = this.dataset.value;
            hideOptions();
            selectBtn.classList.add("select__button_filled");
            });
        });
        document.addEventListener("click", function (e) {
            if (e.target !== selectBtn) {
            hideOptions();
            }
        });
        document.addEventListener("keydown", function (e) {
            if (e.key === "Tab" || e.key === "Escape") {
            hideOptions();
            }

            if($(".select__list.select__list_visible").length){
                var $this = $(".select__item.active");

                if((e.key == "ArrowUp" || e.key == "up") && $(".select__item.active").prev().length != 0){
                    $this.prev().addClass("active");
                    $this.removeClass("active");
                }
                if((e.key == "ArrowDown" || e.key == "down") && $(".select__item.active").next().length != 0){
                    $this.next().addClass("active");
                    $this.removeClass("active");
                }
                if(e.key == "Enter"){
                    $(".select__item.active").click();
                }
            }
        });
        });

        // ? Early Access

    }

    updateEarlyAccessForm() {
        const regExp = /^([\w.+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/;

        if(regExp.test($(".e-access-entry input[type=email]").val())){
            $("input[type=email]").val($(".e-access-entry input[type=email]").val());

            const earlyAccessForm = document.querySelector(".e-access-form");
            const activeStep = document.querySelector(".e-access-form__item_active");
            // CHeck if every required field is filled
            const isFieldsFilled = [...activeStep.querySelectorAll("input.input-field[required]")].every((field) => field.value);
            const optionalFields = activeStep.querySelectorAll("input.input-field.optional");
            const nextStep = activeStep.nextElementSibling;
            // Get height of the form without the children elements
            const parentHeight = parseInt(getComputedStyle(earlyAccessForm).paddingTop) + parseInt(getComputedStyle(earlyAccessForm).paddingBottom);
            // return - if optional field exist and atleast 1 optional field or every required field doesn't filled
            if (!isFieldsFilled || (optionalFields[0] && ![...optionalFields].some((field) => field.value))) return;
            // add class to the form element to make it jump up
            earlyAccessForm.classList.add("e-access-form_active");

            // Hide title on mobile
            document.querySelector(".early-access__title").classList.add("early-access__title_hide");

            // ? Start the fade up animation

            activeStep.style.maxHeight = `${activeStep.scrollHeight}px`;

            earlyAccessForm.style.overflow = "hidden";
            activeStep.classList.add("fade-up");
            activeStep.classList.remove("fade-out");

            if (activeStep.scrollHeight < nextStep.scrollHeight) earlyAccessForm.style.minHeight = `${parentHeight + activeStep.scrollHeight}px`;
            else earlyAccessForm.style.minHeight = null;
            // hide the active step
            activeStep.style.maxHeight = 0;
            nextStep.style.maxHeight = `${nextStep.scrollHeight}px`;

            // setTimeout(() => {
            //   document.querySelector(".early-access__header").classList.add("early-access__header_top");
            // }, 500);
            setTimeout(() => {
                document.querySelector(".early-access__header").classList.add("early-access__header_top");
            }, 100);
            setTimeout(() => {
                activeStep.classList.remove("e-access-form__item_active");
                nextStep.classList.add("e-access-form__item_active", "fade-out");
                setTimeout(() => (earlyAccessForm.style.overflow = null), 500);
            }, 250);
        }
        else{
            alert("Not Email");
        }
    }

    async onSubmit(event: Event) {
        event.preventDefault();
        let hasError = false;
        if (this.formData.controls.name.value == '') {
        this.formData.controls.name.error = 'Name is required';
        hasError = true;
        }
        if (this.formData.controls.firstName.value == '') {
        this.formData.controls.firstName.error = 'First Name is required';
        hasError = true;
        }
        if (this.formData.controls.lastName.value == '') {
        this.formData.controls.lastName.error = 'Last Name is required';
        hasError = true;
        }
        if (this.formData.controls.campers.value == '') {
        this.formData.controls.campers.error = 'Population is required';
        hasError = true;
        }
        if (this.formData.controls.email.value == '') {
        this.formData.controls.email.error = 'Email is required';
        hasError = true;
        } else if (!ApiHelper.validateEmail(this.formData.controls.email.value)) {
        this.formData.controls.email.error = 'Email is invalid';
        hasError = true;
        }

        if (!hasError) {
            $(".select__button").addClass("disabled");
            $("input").each(function(){
                $(this).addClass("disabled");
            });
            $(".buttonContent b").addClass("text-secondary");
            $(".e-access-form__submit .buttonContent .arrow").addClass("d-none");
            $(".e-access-form__submit .buttonContent .loader").removeClass("d-none");

            const result = await ApiHelper.callApi(
                'post',
                '/entities/signup',
                {
                email: this.formData.controls.email.value,
                firstName: this.formData.controls.firstName.value,
                lastName: this.formData.controls.lastName.value,
                name: this.formData.controls.name.value,
                population: this.formData.controls.campers.value,
                jobTitle: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                gRecaptchaResponse: await this.getCaptchaToken(),
                accessCode: $(".e-access-form__title_code").html()
                }
            )

            if (result.status == 1) {
                setTimeout(() => { 
                    $(".e-access-form__item_active .e-access-form__col").animate({'opacity': '0'}, 500);
            
                    $(".e-access-form__item_active").removeClass("e-access-form__item_active fade-out").attr("style", "").next().addClass("e-access-form__item_active fade-out").css("max-height", "268px");
                    $(".e-access-form__item.e-access-msg.mobileFlex").addClass("e-access-form__item_active");
                    setTimeout(() => { 
                        $(".e-access-form__item_active.fade-out .e-access-form__head").css("opacity", "1");
                    }, 1);
                 }, 500);
            } else {
                this.$swal({
                    html: result.message || 'Something was wrong',
                    icon: "error",
                    title: "Oops",
                });
            }
        }
    }
    
    async generatePassword() {
        var length = 8,
            charPool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            pass = "";
        for (var i = 0, n = charPool.length; i < length; ++i) {
            pass += charPool.charAt(Math.floor(Math.random() * n));
        }
        return pass;
    }

    async getCaptchaToken() {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()
        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('')
        return token;
    }

    async created() {
        let accessCode = await this.generatePassword();
        $("body").addClass("earlyAccess");
        setTimeout(() => {
            $("body div:first-child").addClass("mobileFlex");
            $(".e-access-form__title_code").html(accessCode);
        }, 100)
    }

    enterKey(event) {
        if(event.key == "Enter"){
            this.updateEarlyAccessForm();
        }
    }

    updatePopulation(element) {
        $(this)[0].formData.controls.campers.value = element.target.innerHTML;
    }
  }
  