import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App.vue';
import router from './router';
import store from './store';

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const alertOptions = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674"
};

Vue.use(VueSweetalert2, alertOptions);
import { VueReCaptcha } from 'vue-recaptcha-v3';
// For more options see below
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY || "6Le_0-AgAAAAANemp1pkT6J5D_QhH_oogd7EP-M5" })

Vue.config.productionTip = false
new Vue({
  el: '#body-site',
  router,
  store,
  data: {
  },
  methods: {
  },
  render: h => h(App)
})
