
import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
  },
})
export default class Error404 extends TSXComponent<void> {

}
