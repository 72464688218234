
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";

import { routerData } from './router'
import { Route } from "vue-router";

const data: any = routerData

@Component({
  inheritAttrs: false,
  components: {
  },
})
export default class App extends TSXComponent<void> {

  get message() {
    return data.errorData.message
  }

  get redirectName() {
    return data.errorData.redirectName
  }

  get redirectQuery() {
    return data.errorData.redirectQuery
  }
  async mounted() {
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
  }
}
