
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { DirectiveBinding } from "vue/types/options";
import { VNode } from "vue";
import Inputmask from "inputmask";

declare const $: any;

interface Props {
  data: any;
  restrictSpecialCharacters?: boolean;
}

interface Events {
}

@Component({
  inheritAttrs: false,
  components: {},
  directives: {
    letterOnly: {
      update(
        elem: HTMLElement,
        _: DirectiveBinding,
        vNode: VNode,
        oldVnode: VNode,
      ) {
        if (_.value) {
          const el: HTMLInputElement = elem as HTMLInputElement

          const newValue = vNode!.data!.domProps!.value
          const oldValue = oldVnode!.data!.domProps!.value

          if (/^([\w\\'\- ])*$/.test(newValue)) {
            return
          }

          var event = new Event('input', { bubbles: true })
          el.value = oldValue
          el.dispatchEvent(event)
        }
      },
    }
  }
})
export default class FormPassword extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  data!: any;

  @Prop({ required: false })
  restrictSpecialCharacters?: boolean;
  _uid: any;

  inputmask = "";

  created() {
  }

  mounted() {
  }
}
